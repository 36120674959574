#mobileMenu::-webkit-scrollbar {
    width: 12px;
}

#mobileMenu::-webkit-scrollbar-track {
    background: #1b1b1b;
}

#mobileMenu {
    scrollbar-width: 12px;
    scrollbar-color: #1b1b1b;
    -webkit-overflow-scrolling: touch;
}
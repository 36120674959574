html::-webkit-scrollbar {
    display: none;
}

html {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
}

/* Custom scrollbar for WebKit-based browsers (Chrome, Safari, etc.) */
.scrollbar::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
    background: transparent; /* Transparent track background */
}

.scrollbar::-webkit-scrollbar-track {
    background: transparent; /* Make the track transparent */
}

.scrollbar::-webkit-scrollbar-thumb {
    background-color: #121c22; /* Dark scrollbar thumb */
    border-radius: 9999px; /* Fully rounded scrollbar */
}

.scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #1a1f24; /* Lighter color on hover */
}

/* Hide the up/down buttons completely in WebKit-based browsers */
.scrollbar::-webkit-scrollbar-button {
    display: none;
    height: 0;
    width: 0;
    background: none; /* Ensure they are not visible */
}

/* Custom scrollbar for Firefox */
.scrollbar {
    scrollbar-width: thin; /* Thin scrollbar */
    scrollbar-color: #121c22 transparent; /* Thumb visible, track transparent */
}

/* Hover effect for Firefox */
.scrollbar:hover {
    scrollbar-color: #1a1f24 transparent; /* Lighter thumb on hover */
}

/* For completeness, also handle older versions of Edge and IE (if necessary) */
.scrollbar {
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
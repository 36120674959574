.loading-spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000; /* Make sure it appears above other content */
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinner {
    border: 8px solid rgba(0, 0, 0, 0.1);
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border-left-color: #09f;
    animation: spin 1s ease infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}